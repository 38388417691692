
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { StaticQuery, graphql, Link } from "gatsby"

import ResponsiveImage from "components/responsiveImage"
import PageMetaData from "components/pageMetaData"
import { update as updateApp } from "state/app"
import parseHtml from "helper/parseHtml"

import styles from "./styles.module.scss"

function NotFound({ updateApp }) { 

	useEffect(() => {

		updateApp({ headerClass: styles.headerClass });

		return function() {

			updateApp({ headerClass: null });

		}

	});

	return (

		<StaticQuery

			query={graphql`

					query {

						prismic {

							allError_pages {

								edges {

									node {

										background_image
										button_text
										error_message

									}

								}

							}

						}

					}

				`
			}

			render={

				({

					prismic: { allError_pages: { edges: [{ node: { background_image, button_text, error_message } }] } }

				}) => (

					<article className={styles.root}>

						<PageMetaData title={(error_message || [{}])[0].text} />

						<ResponsiveImage src={background_image.url} className={styles.image} />

						<div className={styles.header}>

							<div className={styles.title}>

								{parseHtml(error_message)}

							</div>

							<Link className={styles.button} to="/" title={button_text}>

								{button_text}

							</Link>

						</div>

					</article>

				)

			}

		/>

	)

};

export default connect(

	null,

	dispatch => bindActionCreators({ updateApp }, dispatch)

)(NotFound);
